import React from 'react';
import Faq from 'views/Faq';
import { SEO as Head } from 'components/seo';

const FaqPage = (): JSX.Element => {
  const seo = {
    title: 'Answers to Your Questions',
    description:
      'Coreestate FAQ - Find clarity and insights on commonly asked questions about our platform. Explore our comprehensive FAQ section to get the information you need for seamless navigation and confident decision-making. Empower yourself with knowledge and make informed choices today',
    pathname: '/faq',
  };

  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Faq />
    </>
  );
};

export default FaqPage;
