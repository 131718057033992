import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import Button from '@mui/material/Button';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { formatText } from 'views/Learn/components/AboutToken/AboutToken';

const content = [
  {
    id: 1,
    title: 'What happens if there are not enough investors?',
    content:
      'If the fundraising is not successful we will issue a full refund.',
  },
  {
    id: 2,
    title: 'What digital currencies does Coreestate operate?',
    content:
      // eslint-disable-next-line quotes
      "Currently we accept and can pay dividends in the following digital currencies: <a href='https://www.circle.com/en/euro-coin' target='_blank' rel='noopener'>EUROC</a> and <a href='https://www.aryze.io/' target='_blank' rel='noopener'>eEUR</a>",
  },
  {
    id: 3,
    title: 'How can I receive my dividends from rent?',
    content:
      'You can cash out your dividends at any time after the first dividends are paid. You can track and withdraw your dividends from your Coreestate profile.',
  },
  {
    id: 4,
    title: 'How do you calculate dividends?',
    content:
      'From the monthly rent generated, Coreestate withholds 10% to maintain the property and the rest is split proportionately between the token owners.',
  },
  {
    id: 5,
    // eslint-disable-next-line quotes
    title: "What's the resale price based on in the aftermarket and exchanges?",
    content:
      'The token price will be based on supply and demand on decentralized trading platforms allowing the international trading of tokens. There may be blockchain and platform maintenance fees.',
  },
  {
    id: 6,
    title:
      'If the property requires renovation or work, who is responsible for the execution, site monitoring, and payment?',
    content:
      'We are managing all properties ourselves, a 10% fee is withheld from a monthly rent to cover all maintenance costs.',
  },
  {
    id: 7,
    title: 'If a tenant terminates their lease, who finds a new tenant?',
    content:
      'Coreestate is responsible for finding new tenants as soon as possible.',
  },
  {
    id: 8,
    title: 'Can a company invest?',
    content:
      'Yes, simply choose a business entity in a register form and follow the guidlines. ',
  },
];

const InfoTitle = styled(Typography)`` as StyledPropsFix;
const InfoText = styled(Typography)`` as StyledPropsFix;
const ContentBox = styled(Container)``;

const FAQSection = styled(Box)`
  margin-left: 5.5em;
  margin-right: 17em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 0.3em;
  }

  .faqSection-contentBox {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.4em auto;
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: 1em 3.3em 1em 3em;
  }
  .faqSection-infoText {
    font-family: Roboto Regular;
    padding-bottom: 1.6em;
    font-size: 1.1em;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 0.9em;
    }
  }
  .faqSection-infoTitle {
    font-family: Roboto Bold;
    font-size: 1.5em;
    margin: 0.5em 0;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
    }
  }
`;

const LearnButton = styled(Button)`
  :hover {
    border: unset;
    background-color: rgb(64 130 109 / 78%);
  }

  padding: 14px 26px;
  border: unset;
  background-color: #40826d;
  font-family: Roboto Regular;
  font-size: 0.9em;
  text-transform: none;
  color: white;
  white-space: nowrap;
  margin: 0;
  align-self: end;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    transform: scale(1);
  }
  ${({ theme }) => theme.breakpoints.only('xs')} {
    transform: scale(0.8);
  }
` as StyledPropsFix;

export const FaqSection = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <FAQSection sx={{ maxWidth: `${sizeOfGrid}px` }}>
      {content.map((card) => (
        <ContentBox className="faqSection-contentBox" key={card.id}>
          <InfoTitle className="faqSection-infoTitle" component="h1">
            {card.title}
          </InfoTitle>
          <InfoText className="faqSection-infoText" component="p">
            {formatText(card.content)}
          </InfoText>
        </ContentBox>
      ))}
      <Divider height="2.3em" />
      <LearnButton href="/how-it-works#top">
        Learn more how it works
      </LearnButton>
    </FAQSection>
  );
};
