import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
// import Link from 'layouts/Main/components/Footer/FooterLink';

const content = [
  {
    id: 1,
    title:
      'Is there a complete guide on how to buy digital shares on Coreestate?',
    content: [
      // eslint-disable-next-line quotes
      "Yes, we have created a <a href='https://coreestate.gitbook.io/documentation/' target='_blank' rel='noopener'>step-by-step guide</a> that starts with what's necessary before creating a profile and ends with how to access all the documentation that verifies your ownership.",
    ],
  },
  {
    id: 2,
    title: 'What are Digital Shares?',
    content: [
      // eslint-disable-next-line quotes
      "Coreestate issued Digital Share is simply a tokenized share of a registered public company in Denmark owning the property. Each digital share represents investors' ownership of a specific public company owning the property.",
    ],
  },
  {
    id: 3,
    title: 'What does it mean my investment is asset-backed?',
    content: [
      // eslint-disable-next-line quotes
      "It means that your digital share is pegged to the physical asset such as real estate. It's not possible to split the real estate ownership itself into thousands of pieces however, it's possible to split the public company ownership owning and operating the real estate.",
      // eslint-disable-next-line quotes
      "Therefore, by owning a share of the company, investors become shareholders and co-owners of all the company's assets.",
    ],
  },
  {
    id: 4,
    title: 'How can I purchase?',
    content: [
      // eslint-disable-next-line quotes
      "Use the Create Account button and follow the guidelines. We accept Credit Cards, Bank Transfers, Euro Coin (<a href='https://www.circle.com/en/euro-coin' target='_blank' rel='noopener'>EUROC</a>) and Aryze <a href='https://www.aryze.io/' target='_blank' rel='noopener'>eEUR</a> Euro-Backed Stablecoins via Wallet Connect connected wallets. We also offer payouts in EUROC to investors' whitelisted wallets.",
    ],
  },
  {
    id: 5,
    title: 'How often do I get payouts?',
    content: [
      'Collected rent will be paid out as a dividend on a quarterly basis to your wallet in the Coreestate account. See more in FAQ.',
    ],
  },
  {
    id: 6,
    title: 'Can I sell my digital shares after the STO is completed?',
    content: [
      'Yes! Once the Digital Share Offering (STO) is successfully completed, you have the opportunity to sell your digital shares. However, the tokens are not currently listed on any secondary exchange and there is no guarantee of listing following the completion of the STO. The company is actively exploring secondary trading options with digital share exchanges such as <a href="https://realestate.exchange/" target="_blank" rel="noopener">https://realestate.exchange/</a>',
    ],
  },
  {
    id: 7,
    title: 'Can I be certain about my return?',
    content: [
      // eslint-disable-next-line quotes
      'Yes, the investor can have assurance that their return is ensured as long as the apartments are leased and the real estate prices rise. There are no financial obligations associated with the Coreestate projects, which implies that the investment can only experience depreciation if there is a temporary adjustment in the real estate market.',
    ],
  },
  {
    id: 8,
    title: 'Why purchase digital shares on Coreestate?',
    content: [
      // eslint-disable-next-line quotes
      "For investors looking to enter the digital currency market, investing in digital shares may be the best way to ease into it. Since tokens represent company shares, similar to stocks, the concept should be fairly familiar. Additionally, it is a chance to own real estate where ownership rights are protected by the immutable nature of blockchain, meaning they cannot be changed without the owner's permission. This enhances security and trust in the investment process.",
    ],
  },
];

const Title = styled(Typography)`` as StyledPropsFix;
const InfoTitle = styled(Typography)`` as StyledPropsFix;
const InfoText = styled(Typography)`` as StyledPropsFix;
const ContentBox = styled(Container)``;

const AboutTokenSection = styled(Box)`
  margin-left: 5.5em;
  margin-right: 17em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 0.3em;
  }

  .aboutTokenSection-contentBox {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.4em auto;
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: 1em 3.3em 1em 3em;
  }
  .aboutTokenSection-infoText {
    font-family: Roboto Regular;
    padding-bottom: 1.6em;
    font-size: 1.1em;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 0.9em;
    }
  }
  .aboutTokenSection-title {
    font-family: Poppins SemiBold;
    font-size: 2em;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .aboutTokenSection-infoTitle {
    font-family: Roboto Bold;
    font-size: 1.5em;
    margin: 0.5em 0;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
    }
  }
`;

const AboutToken = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <AboutTokenSection sx={{ maxWidth: `${sizeOfGrid}px` }}>
      <Title
        className="aboutTokenSection-title"
        component="h1"
        id="coreestate-token"
      >
        Coreestate token
      </Title>
      <Divider height="3.3em" />
      {content.map((card) => (
        <ContentBox className="aboutTokenSection-contentBox" key={card.id}>
          <InfoTitle className="aboutTokenSection-infoTitle" component="h1">
            {card.title}
          </InfoTitle>
          {card.content.map((content, index) => (
            <InfoText
              className="aboutTokenSection-infoText"
              component="p"
              key={index}
            >
              {formatText(content)}
              {/* {card.link ? (
                <Link href={card.link} style={{ paddingLeft: '0.3rem' }}>
                  here.
                </Link>
              ) : null} */}
            </InfoText>
          ))}
        </ContentBox>
      ))}
    </AboutTokenSection>
  );
};

export default AboutToken;

export function formatText(content: string) {
  const htmlTags = ['<p', '<a'];
  const containsHTML = htmlTags.some((el) => content.includes(el));

  if (containsHTML)
    return <span dangerouslySetInnerHTML={{ __html: content }}></span>;
  return content;
}
