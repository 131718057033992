import React from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { FaqSections } from './components';
import { useTheme } from '@mui/material/styles';

export const FaqView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main
      bgcolor={theme.palette.background.level1}
      sectionTitle="Frequently asked questions"
    >
      <Container>
        <FaqSections />
      </Container>
    </Main>
  );
};
